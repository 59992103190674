<template>
  <div>
    <button @click="checkStick" id="countries-btn"
      class="btn btn-secondary dropdown-toggle ddb dropdown-toggle dropdown-filter" type="button" data-toggle="modal"
      data-target="#countriesModal">
      {{ selectedCountry }}
    </button>
    <div class="modal fade" id="countriesModal" tabindex="-1" aria-labelledby="countriesModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-dark" id="exampleModalLabel">Countries</h5>
            <span type="button" class="close" data-dismiss="modal">&times;</span>
          </div>
          <div style="font-size: medium;text-align:center;" class="row modal-body text-center">
            <div style="margin: 1px;background-color: #0e0d0d;color: white;width:auto;"
              class="btn btn-sm col text-center">
              <div @click="setCategory('', 'All Countries')">
                <div class="item-wrapper">
                  <img src="/img/flags/world.png" style="width: 20px;" alt="Flags" />
                  <span class="ml-2 item-wrapper-span-active">All Countries </span>
                </div>
              </div>
            </div>
            <div style="margin: 1px;background-color: #0e0d0d;color: white;width:auto;"
              class="btn btn-sm col text-center" v-for="(c, index) in countries" :key="getCountryKey(c, index)">
              <div @click="setCategory(c, c.country_name)">
                <div class="item-wrapper">
                  <img :src="getCountryFlag(c.country_code)" width="13" height="13" alt="country flag" />
                  <span class="ml-2 item-wrapper-span-active text-white">{{ c.country_name }} ({{ c.games }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "../../services/api";
import fix from "@/services/fix";

export default {
  name: "countries",
  data: function () {
    return {
      countries: [],
      selectedCountry: 'All Countries',
    };
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    category_id: function () {
      return this.$store.state.category_id;
    },

  },
  methods: {
    checkStick: function () {
      document.getElementById('dp').classList.remove('stick');
    },
    getCountries: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/categories";

      fix
        .get(path)
        .then((res) => {
          vm.countries = res.data.map((item) => ({
            category_id: item.category_id,
            country_code: item.country_code,
            country_name: item.country_name,
            games: item.games
          })).slice(0, 25);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCountryKey: function (league, index) {
      var prefix =
        league === undefined || league.sport_id == undefined
          ? index
          : league.sport_id;
      return Math.random()
        .toString(10)
        .replace("0.", "sport-id-" + prefix + "-");
    },

    setCategory: function (country, country_name) {
      document.getElementById("countries-btn").click();
      this.menu = "country";
      this.selectedCountry = country_name;
      if (country === "") {
        this.$store.dispatch("setCategoryID", "");
      } else {
        this.$store.dispatch("setCategoryID", country.category_id);
      }
    },

  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}
</style>